import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Space, Row, Col, Form, Typography,Dropdown, Card, Table } from 'antd'
import { ActionButton, AddVendorUserDrawer, AlertModal, IptvSearch, ModuleTopHeading } from '../../components';
import {
    DeleteOutlined,
    EditOutlined,
    FilterOutlined,
} from '@ant-design/icons'
import { actionsApi, TableLoader } from '../../shared';
const { Text } = Typography
const suffix = (
  <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
);

const VendorUsers = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [visible, setVisible]= useState(false);
  const { addvendorDetail, loadingAddVendorDetail, filters, pagination } = useSelector(state => state?.mastervendor)
  const [editvendor, setEditVendor] = useState(null);
  const [ deletevendor, setDeleteVendor ] = useState(null)
  const [visiblemodal, setVisibleModal] = useState(false);
  const [ loading, setLoading] = useState(false);
  const [order, setOrder] = useState(1)  

  const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Sort by vendor name</a>,
            key: '2',
        },
  ];

    useEffect(() => {
        dispatch(actionsApi?.getAddVendorDetail({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)

    }, [])
    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAddVendorDetail({ ...data, order, ...pagination, pageNo: 1 }))
        dispatch(actionsApi?.changeAddvendorDetailFilters({ ...data, order }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddVendorDetail({ ...filters, pageNo, pageSize }))
    }
    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
    };

   


    const columns = [
        {
            title: <Text>MASTER VENDOR NAME</Text>,
            dataIndex: 'name',
        },
        {
            title: <Text>EMAIL ADDRESS</Text>,
            dataIndex: 'email',
        },
        {
            title: <Text>AVAILABLE TOKENS</Text>,
            dataIndex: 'token',
        },
        {
            title: <Text>TOTAL VENDOR USERS</Text>,
            dataIndex: 'userCount',
        },

        {
            title: <Text>ACTION</Text>,
            key: 'action',
            fixed:'right',
            width:100,
            render: (data,row,d) => (
                <Space>
                    <ActionButton
                        title='Edit Vendor'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true);setEditVendor(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleModal(true);setDeleteVendor({id:row?.id, name:row?.name})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
        },
    ];

  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col md={12}>
                    <ModuleTopHeading
                        name='Master vendor'
                        onClick={()=>{setVisible(true)}}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <IptvSearch
                            name="search"
                            value={form.getFieldValue('search') || ''}
                            suffix={suffix}
                            placeholder='Search'
                        />
                        <Dropdown
                            menu={{
                            items
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                        >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={addvendorDetail} 
                        style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                        scroll={{x: 1000}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loadingAddVendorDetail
                            }
                        }
                    />
                </Col>
            </Row>

            <AddVendorUserDrawer
                visible={visible}
                editvendor={editvendor}
                getVendorApplications={call}
                onClose={()=> {setVisible(false);setEditVendor(null)}}
            />
            <AlertModal
                visiblemodal={visiblemodal}
                deletevendor={deletevendor}
                getVendorApplications={call}
                onClose={()=>{setVisibleModal(false);setDeleteVendor(null)}}
            />
        </Card>
    </div>
  )
}

export {VendorUsers}