import { addBoxApi } from './AndroidBoxApi'
import { vendorApi } from './VendorApi'
import { MasterportalApi } from './MasterportalApi'
import { ChangePassApi } from './ChangePassApi'
import { ProfileApi } from './ProfileApi'
 
const apiCalls={
    addBoxApi,
    vendorApi,
    MasterportalApi,
    ChangePassApi,
    ProfileApi
}
export {apiCalls}