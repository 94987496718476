import React from 'react'
import { Modal, Space, Typography } from 'antd'

const LinkViewModal = ({visiblelink,onClose}) => {

    const data = {
        title:'Box “AD12”',
        belowcontent:[
            {
                title:'M3U',
                desc: 'http://abc.com',
            },
            {
                title:'Stalker',
                desc: 'http://xyz.com',
            },
            {
                title:'Xtream Code API URL',
                desc: 'http://xyz.com',
            },
            {
                title:'Username: Rehmat Ali',
                desc: 'Password: abcd1234',
            },
        ]
    }
  return (
    <Modal
        centered
        open={visiblelink}
        onCancel={onClose}
        footer={false}
        className='text-center'
      >
        <Typography.Title level={5}>{data?.title}</Typography.Title>
        <Space direction='vertical' size={15} align='center'>
        {
            data?.belowcontent?.map((data,index)=>
            <Space direction='vertical' key={index} className='text-center' size={0}>
                <Typography.Text strong>{data?.title}</Typography.Text>
                <Typography.Text>{data?.desc}</Typography.Text>
            </Space>
            )
        }
        </Space>
        
      </Modal>
  )
}

export {LinkViewModal}