import React from 'react'
import { Modal, Table, Typography } from 'antd'
import { ActionButton } from '../PageComponents';
import { DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography

const ActivityLogModal = ({visible,onClose}) => {


    const columns = [
        {
            title: <Text>BOX NAME</Text>,
            dataIndex: 'boxname',
            key: 'boxname',
        },
        {
            title: <Text>ACTIVITY</Text>,
            dataIndex: 'activity',
            key: 'activity',
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            width:100,
            align:'center',
            render: (_, row) => (
                <ActionButton
                    title='Delete Item'
                    icon={<DeleteOutlined className='danger-color'/>}
                    type='ghost'
                    danger
                />
            ),
          },
      ];
      const data = [
        {
            key: '1',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '2',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '3',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '4',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '5',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '6',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '7',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
        {
            key: '8',
            boxname: <Text>AD12</Text>,
            activity: <Text>URL is not delete </Text>,
        },
      ];

  return (
    <Modal
        title="Activity Log"
        centered
        open={visible}
        onCancel={onClose}
        footer={false}
        width={700}
      >
        <Table
            size='large'
            columns={columns} 
            dataSource={data}     
            pagination={false} 
            style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}   
            scroll={{y: 300}}
        />

        
    </Modal>
  )
}

export {ActivityLogModal}