import React, { useState } from 'react'
import { Card, Space, Typography, message, Upload, Image, Row, Form, Col, theme, Button } from 'antd'
import { IptvCheckbox } from '../checkbox'
import { read, utils } from 'xlsx';
import { checkAuthorization, domainUrl } from '../../shared';

const { useToken } = theme;
const { Dragger } = Upload;

const ImportDevices = () => {

    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)

    const [exceldata, setExcelData] = useState([]);
    const file_type = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    const {userToken} = checkAuthorization()
    const handleChange = (info) => {
        const { file } = info;
        if (file.status !== 'uploading' && file.originFileObj) {
            const uploadedFile = file.originFileObj;
            if (file_type.includes(uploadedFile.type)) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;

                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        setExcelData(rows);
                    }
                };
                reader.readAsArrayBuffer(uploadedFile);
            } else {
                console.log('Please upload only Excel file');
            }
        }
    };

    const props = {
        name: 'file',
        multiple: false,
        action: domainUrl + '/importDevices',
        headers: {
            Authorization: userToken,
        },
        onChange: handleChange,
    };


const UploadFilePost=()=>{
  setLoading(true)
  let data= form.getFieldsValue()
  let formData = new FormData()
  formData.append('file', data?.file)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", userToken);
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
  }
  fetch(domainUrl + '/importDevices', requestOptions)
  .then(response => response.json())
  .then(result => {
      console.log(data)
      if(result?.success)
          {
              form.resetFields()
              setLoading(false)
              message.success('File uploaded successfully')
          }
      else
      {
          setLoading(false)
          message.error('File uploading is fail')
      }
  })
  .catch(error => {message.error(error)})
}



// const props = {
//   name: 'file',
//   multiple: false,
//   action: Urldomain + '/importDevices',
//   headers: {
//     Authorization: `Bearer ${TokenUrl}`,
// },
//   onChange(info) {
//       const { file } = info;
//       if (file.status !== 'uploading') {
//           console.log(file, info.fileList);
//           form.setFieldsValue({...form.getFieldsValue(),file:file?.originFileObj})
//         }
//   },
// }


  return (
    <div className='pt-3'>
        <Card
            className='radius-12 border0'
          actions={[
            <Space className="w-100 px-3" style={{justifyContent:'end'}}>
                <Button>
                    Sample format
                </Button>
                <Button  
                    block
                    type="primary"
                    onClick={UploadFilePost}
                >
                    Upload CSV
                </Button>
            </Space>
          ]}
        >
            <Space direction='vertical' size={5} className='w-100'>
                <div>
                  <Typography.Title level={4} className='text-capitalize'>Import devices</Typography.Title>
                  <IptvCheckbox
                    name='masterportal'
                    label='Include master portal'
                  />
                </div>
                <Space align='center' size={10}>
                    <Typography.Title level={5} className='m-0'>Import CSV</Typography.Title>
                    <Typography.Title level={5} className='m-0 brand-color'>Maximum 200 devices import at one time</Typography.Title>
                </Space>
                <Row gutter={[12,12]} justify={'center'}>
                    <Col lg={{span: 6}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                        <div className='my-3'>
                            <Dragger {...props} className="dragcs w-50">
                                <Typography.Text strong className='mb-3' style={{display:'block'}}>Add new boxes by csv</Typography.Text>
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                </p>
                                <p className="ant-upload-text grayish">Import csv file</p>
                            </Dragger>
                        </div>
                    </Col>
                    <Col lg={{span: 6}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                        <div className='my-3'>
                            <Dragger {...props} className="dragcs w-drop">
                                <Typography.Text strong className='mb-3' style={{display:'block'}}>Edit boxes by csv</Typography.Text>
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                </p>
                                <p className="ant-upload-text grayish">Import csv file</p>
                            </Dragger>
                        </div>
                    </Col>
                </Row>
            </Space>
        </Card>
    </div>
  )
}

export {ImportDevices}