import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Select, message} from "antd"
import { IptvInput } from "../input"
import FormItem from "antd/es/form/FormItem";
import { apiCalls } from "../../shared/Apis";
import { checkAuthorization, domainUrl } from "../../shared";
import { Iptvselect } from "../Iptvselect";
import { methodAllow, methodUrlType, methodsUrl } from "../../shared/lookups";

const { Option } = Select;

const AddboxDrawer = ({visible, onClose, editbox, getAllApplications}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [vendoridData, setVendorIdData] = useState([]);
    const [ displayinputs, setDisplayInputs ] = useState(false)

    const InputDisplay = (e) => {
        setDisplayInputs(e === '3' ? true : false);
    }
    

    useEffect(() => {
        if(visible){
            fetchVendorIdData()
        }
        if (visible && editbox) {
            EditboxApi(editbox)
        } else {
            form.resetFields();
        }
    }, [visible, editbox]);

    
    const updateCreateApi = async (formData) => {
        setLoading(true);
        try {
          const result = await apiCalls.addBoxApi.updateCreateApi({
            ...formData,
            id: editbox,
          });
          setLoading(false);
          getAllApplications();
          setDisplayInputs(false)
          if(result){
            onClose();

          }
        } catch (error) {
          message.error("Failed to update box");
        }
      };
    

    // get vendor id in dropdown
    const fetchVendorIdData = () => {
        const {userToken} = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append('Authorization', userToken);
        fetch(domainUrl + "/vendorDropDown", {
            method: "GET",
            headers: myHeaders
        })
        .then(response => response.json())
        .then(result => {
            console.log('Vendor id', result?.vendor)
            if (result?.success) {
                const data = result?.vendor.map(vendor => ({
                    value: vendor.id,
                    label: vendor.name,
                }));
                setVendorIdData(data);
            }
        })
        .catch(error => {
            message.error('Failed to fetch Vendor Id');
        });
    };

    //edit android api
    const EditboxApi = (id) => {
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        let data= form.getFieldsValue()
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/editAndroidBox/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                console.log("Edit android", result) 
                form.setFieldsValue(result?.androidBox)
                setSelectedOption(data?.vendorID);
                }
            else
            {
                throw  'error'
            }
        })
        .catch(error => {alert(error)})
    }

    const handleBoxChange = (value) => {
        setSelectedOption(value);
        const vendorID = form.getFieldValue('vendorID');
        console.log('Vendor ID:', vendorID);
    };
    
    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            updateCreateApi(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  

  return (
    <div>
        <Drawer
            title={editbox? 'Edit Box' : 'Add Box'}
            onClose={onClose}
            open={visible}
            width={700}
            footer={
                <div className="w-100">
                    <Space className="w-100">
                        <Button 
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            block
                            loading={loading}
                            onClick={()=> form.submit()}>
                            {
                                editbox? 'Update box' : 'Save'
                            }
                        </Button>
                    </Space>
                </div>
            }
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='boxName'
                            label='Android Box Name'
                            placeholder='e.g Jhone'
                            required
                            message='Please enter android box name'
                            value={form.getFieldValue("boxName") || ''} 

                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='serialNo'
                            label='Serial number'
                            placeholder='e.g 1234'
                            required
                            message='Please enter serial number'
                            value={form.getFieldValue("serialNo") || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <FormItem
                            name='vendorID'
                            label='Vendor name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose vendor name',
                                },
                            ]}
                        >
                            <Select
                                value={selectedOption}
                                onChange={handleBoxChange}
                                placeholder='Choose vendor name'
                                style={{ width: "100%" }}
                            >
                                {vendoridData.map(options => (
                                    <Option key={options.value} value={options.value}>
                                        {options.label}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='macAddress'
                            label='MAC Address'
                            placeholder='e.g 00:B0:D0:63:C2:46'
                            required
                            message='Please enter MAC address'
                            value={form.getFieldValue("macAddress") || ''}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const cleanedValue = inputValue.replace(/\W/g, '');
                                const formattedMacAddress = cleanedValue.match(/.{1,2}/g)?.join(':').slice(0, 17) || '';
                                form.setFieldsValue({ ...form?.getFieldsValue(), macAddress: formattedMacAddress });
                            }}                          
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <Iptvselect
                            label= 'Update URL'
                            name="updateUrl" 
                            required
                            message='Please choose update url'
                            placeholder='Choose updated url'
                            value={form.getFieldValue('updateUrl') || ''}
                            options={methodAllow}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='Customer Name'
                            name='customerName'
                            placeholder='e.g Jhone'
                            required
                            message='Please enter customer name'
                            value={form.getFieldValue("customerName") || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <FormItem
                            name='masterPortal'
                            label='Master Portal'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose master portal',
                                },
                            ]}
                        >
                            <Select
                                value={form.getFieldValue("masterPortal") || ''}
                                placeholder='Choose option'
                                style={{ width: "100%" }}
                            >
                                <Option value={'Yes'}>
                                        Yes
                                </Option>
                                <Option value={'No'}>
                                        No
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <Iptvselect
                            label= 'Select URL'
                            name="selectUrl" 
                            required
                            message='Please choose select url!'
                            placeholder='Select URL'
                            value={form.getFieldValue('selectUrl') || ''}
                            options={methodsUrl}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <Iptvselect 
                            label= 'URL Type'
                            name="urlType" 
                            required
                            message='Please choose url type!'
                            placeholder='Select URL Type'
                            value={form.getFieldValue('urlType') || ''}
                            onChange={InputDisplay}
                            options={methodUrlType}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='url'
                            label='URL'
                            placeholder='e.g www.example.com'
                            required
                            message='Please enter url'
                            value={form.getFieldValue("url") || ''}
                        />
                    </Col>
                    {
                        displayinputs?
                            <>
                                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                    <IptvInput
                                        label='Username'
                                        name='userName'
                                        placeholder='e.g Jhone'
                                        required
                                        message='Please enter url'
                                        value={form.getFieldValue("userName") || ''}
                                    />
                                </Col>
                                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                    <IptvInput
                                        label='Password'
                                        type='password'
                                        name='password'
                                        required
                                        message={()=>{}}
                                        placeholder='e.g A@123456'
                                        value={form.getFieldValue("password") || ''}
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                        validator={({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                                const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                                if (!reg.test(value)) {
                                                    return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })}
                                    />
                                </Col>
                            </>
                        :
                        <></>
                    }
                </Row>
                
            </Form>
        </Drawer>
    </div>
  )
}

export {AddboxDrawer}