import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton } from 'antd'

const { useToken } = theme;
const { Title, Text } = Typography
const CardComponent = () => {
    const { token } = useToken();
    const [ skeletonactive, setSkeletonActive] = useState(true)

    setTimeout(() => {
        setSkeletonActive(false)
    }, 2000);

    const data = [
        {
            title:'25.1k',
            subtitle:'Total Master Vendors',
            img:'totalapp.png',
        },
        {
            title:'2,435k',
            subtitle:'Total Android Devices',
            img:'download.png',
        },
    ]
  return (
    <div>
        <Row gutter={[24,24]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={c}>
                        {
                            skeletonactive?
                                <Skeleton.Button
                                    active
                                    size='large' 
                                    shape='square'
                                    block
                                    style={{width:'100%', height:'114px',borderRadius:20}}
                                />
                                :
                                <Card style={{borderColor: token.borderCardcolor,borderRadius: token.borderCardrad,boxShadow: token.cardshadow,width:"100%"}}>
                                        <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                            <div>
                                                <Text className='text-input' strong>{cdata?.subtitle}</Text>
                                                <Title level={3} className='text-blue my-0'>{cdata?.title}</Title>
                                            </div>
                                            <Image src={'/assets/icons/'+cdata?.img} width={'24px'} />
                                        </Space>
                                    </Card> 
                                }  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}