import React,{ useState } from 'react'
import { Button, Image, Space, Row, Col, Form, Typography,Dropdown, Card, Table } from 'antd'
import { ActionButton, ActivityLogModal, AlertModal, IptvSearch, ModuleTopHeading } from '../../components';
import { DeleteOutlined, FileDoneOutlined, FilterOutlined } from '@ant-design/icons';
const { Text } = Typography
const suffix = (
    <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
);

const ServerPortal = () => {
    const [form] = Form.useForm()
    const [visible, setVisible]= useState(false)
    const [visiblemodal, setVisibleModal] = useState(false);
    const [servername,setServerName] = useState()
  
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Sorting by alpha-numeric</a>,
            key: '2',
        },
    ];
  
  
    const columns = [
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macaddress',
            key: 'macaddress',
        },
        {
            title: <Text>USER NAME</Text>,
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: <Text>PASSWORD</Text>,
            dataIndex: 'password',
            key: 'password',
        },
        {
            title: <Text>TYPE</Text>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <Text>URL ADDRESS</Text>,
            dataIndex: 'urladdress',
            key: 'urladdress',
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            width:100,
            fixed: 'right',
            align:'center',
            render: (_, row) => (
                <ActionButton
                    title='Delete Box'
                    icon={<DeleteOutlined className='danger-color'/>}
                    onClick={()=>{setVisibleModal(true);setServerName(row?.username)}}
                    type='ghost'
                    danger
                />
            ),
        },
    ];
    const data = [
        {
            key: '1',
            macaddress:   <Text>01:B0:D0:63:C2:46</Text>,
            username:   <Text>Nazli Hasan</Text>,
            password:   <Text>1234abcd</Text>,
            type:   <Text>Server</Text>,
            urladdress:   <Text>http://www.abc.com</Text>,
        },
        {
            key: '2',
            macaddress:   <Text>02:B0:D0:63:C2:46</Text>,
            username:   <Text>Ahmad Hasan</Text>,
            password:   <Text>1234abcd</Text>,
            type:   <Text>Server</Text>,
            urladdress:   <Text>http://www.abc.com</Text>,
        },
        {
            key: '3',
            macaddress:   <Text>03:B0:D0:63:C2:46</Text>,
            username:   <Text>Zubaida Sikandar</Text>,
            password:   <Text>1234abcd</Text>,
            type:   <Text>Server</Text>,
            urladdress:   <Text>http://www.abc.com</Text>,
        },
        {
            key: '4',
            macaddress:   <Text>04:B0:D0:63:C2:46</Text>,
            username:   <Text>Arman Khan</Text>,
            password:   <Text>1234abcd</Text>,
            type:   <Text>Server</Text>,
            urladdress:   <Text>http://www.abc.com</Text>,
        },
        {
            key: '5',
            macaddress:   <Text>05:B0:D0:63:C2:46</Text>,
            username:   <Text>Tahzeem Hasan</Text>,
            password:   <Text>1234abcd</Text>,
            type:   <Text>Server</Text>,
            urladdress:   <Text>http://www.abc.com</Text>,
        },
    ];
  
    return (
        <div>
            <Card className='radius-12 border0'>
                <Row gutter={[12,12]}>
                    <Col md={6}>
                        <ModuleTopHeading
                            name='Server Portal'
                        />
                    </Col>
                    <Col lg={{span: 18}} md={{span: 18}} xs={{span: 24}} sm={{span: 24}}>
                        <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                            <IptvSearch
                                name="search"
                                size='large'
                                value={form.getFieldValue('search') || ''}
                                suffix={suffix}
                                placeholder='Search'
                            />
                            <Dropdown
                                menu={{
                                items
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    type='primary'
                                    icon={<FilterOutlined/>}
                                    size='large'
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                            <Button
                                type='primary'
                                icon={<FileDoneOutlined />}
                                size='large'
                                onClick={()=> setVisible(true)}
                                className='btn-color-other'
                            >
                                Activity log
                            </Button>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                            scroll={{
                                x: 1000,
                                }}
                            pagination={{ 
                                hideOnSinglePage: true, 
                                total: 12,
                                // total: pagination?.totalRecords,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions:['20', '50', '100'],
                                //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                                showTotal: total => <Button>Total: {total}</Button>
                            }}
                        />
                    </Col>
                </Row>  
                <AlertModal
                    visiblemodal={visiblemodal}
                    servername={servername}
                    onClose={()=>setVisibleModal(false)}
                />
                <ActivityLogModal 
                    visible={visible}
                    onClose={()=> setVisible(false)}
                />
            </Card>
        </div>
    )
}

export {ServerPortal}