import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

// export const getBoxStatistics = createAsyncThunk('getBoxStatistics',
//     async (_, { dispatch, getState }) => {

//         const {userToken}= checkAuthorization()
//         dispatch(gettingaddboxStatistics())
//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", userToken)
//         var requestOptions = {
//             method: 'get',
//             headers: myHeaders,
//             redirect: 'follow'
//         };
//         fetch(domainUrl + `/viewAllAndroidBoxes`, requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result?.success) {
//                     dispatch(statisticsResponse(result))
//                 }
//                 else 
//                     throw result?.message
//             })
//             .catch(error => { 
//                 dispatch(statisticsClear())
//                 message.error(error) 
//             })
//     }
// )
export const getAddBoxDetail = createAsyncThunk('getBoxStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddBoxDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title, order: object?.order})
        };
        fetch(domainUrl + `/viewAllAndroidBoxes`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addboxDetailResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addboxDetailClear())
                message.error(error) 
            })
    }
)

const AddBoxSlice = createSlice({
    name: 'addandbox',
    initialState: {
        data: null,
        loading: false,
        addboxDetail: null,
        loadingAddBoxDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        getBoxStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingAddBoxDetail: state => {
            state.loadingAddBoxDetail=true
            state.addboxDetail=null
        },
        addboxDetailResponse: (state, action)=>{
            state.addboxDetail= action.payload
            state.loadingAddBoxDetail= false
        },
        addboxDetailClear: (state)=>{
            state.addboxDetail= null
            state.loadingAddBoxDetail= false
        },
        changeAddboxDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingAddBoxDetail, addboxDetailResponse, addboxDetailClear,
    changeAddboxDetailFilters, setPages
} = AddBoxSlice.actions;
export default AddBoxSlice.reducer;