import { useState } from "react"
import { Space, Typography, Avatar} from "antd"
import {CaretDownOutlined, UserOutlined} from '@ant-design/icons'
import UserProfileDrawer from "./UserProfileDrawer"
const {Title}= Typography

export const UserProfile = () => {
    const [visible, setVisible]= useState(false)
    return (
        <>
          <Space 
            style={{ cursor: 'pointer' }}
            onClick={()=> setVisible(true)}
          >
            <Avatar
              size={36}
              icon={<UserOutlined/>}
            />
            <Space size={2}>
                <Title level={5} className='my-0'>Admin</Title>
                <CaretDownOutlined style={{ transform: 'translateY(2px)' }} />
            </Space>
          </Space>
          <UserProfileDrawer
            visible={visible}
            onClose={()=> setVisible(false)}
          />
        </>
    )
}