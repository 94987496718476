import React, {Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes as Switch} from 'react-router-dom'

// import {actionsApi} from "../shared";

import Protected from './Protected'
// import { useDispatch, useSelector } from 'react-redux'
const Entry = lazy(() => import('../pages/SideBar/index.jsx'))

const  RouteF = () => {
    // const {data} = useSelector(state => state?.login)    
    // const appDispatcher=useDispatch()
    const [token, setToken]= useState(null)
    useEffect(()=>{
        const tokenString = localStorage.getItem('token')
      //  if(tokenString)
      //   appDispatcher(actionsApi?.AuthCheck())
    },[])
    // useState(()=>{
    //     // if(data)
    //     // {
    //         const tokenString = localStorage.getItem('token');
    //       //  setToken(JSON.parse(tokenString))
    //     // }
    // },[data])
 

       return (
          <Switch>
              <Route 
                  path="/*" 
                  element={
                      <Protected>
                          <Entry />
                      </Protected>
                  }
              />
          </Switch>
       )
}

export default RouteF