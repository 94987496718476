import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Space, Row, Col, Form, Typography, Card, Table, Dropdown } from 'antd'
import { AddboxDrawer, ImportDevices, LinkViewModal, IptvSearch, ConfirmBoxModal, ActionButton, ModuleTopHeading } from '../../components';
import { actionsApi } from '../../shared';
import {
    DeleteOutlined,
    EditOutlined,
    FilterOutlined
} from '@ant-design/icons'
import { TableLoader } from '../../shared';
import { Link } from 'react-router-dom';
const { Text } = Typography
const suffix = (
    <Image src='/assets/icons/search-ic.png' width={'14.86px'} preview={false} />
);


const AddEditBox = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { addboxDetail, loadingAddBoxDetail, filters, pagination } = useSelector(state => state?.addandbox)
    const [visible, setVisible] = useState(false)
    const [visiblemodal, setVisibleModal] = useState(false);
    const [editbox, setEditBox] = useState(null)
    const [visiblelink, setVisibleLink] = useState(false)
    const [deletebox, setDeleteBox] = useState()
    const [order, setOrder] = useState(1)

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Sort by master vendor</a>,
            key: '2',
        },
    ];

    useEffect(() => {
        dispatch(actionsApi?.getAddBoxDetail({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)

    }, [])
    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAddBoxDetail({ ...data, order, ...pagination, pageNo: 1 }))
        dispatch(actionsApi?.changeAddboxDetailFilters({ ...data, order }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddBoxDetail({ ...filters, pageNo, pageSize }))
    }
    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
    };

    const columns = [
        {
            title: <Text>BOX NAME</Text>,
            dataIndex: 'boxName',
            key: 'boxName',
            fixed: 'left',
        },
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: <Text>SERIAL NUMBER</Text>,
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: <Text>IP ADDRESS</Text>,
            dataIndex: 'ipaddress',
            key: 'ipaddress',
        },
        {
            title: <Text>MVN / VU</Text>,
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: <Text>URLS</Text>,
            dataIndex: 'url',
            key: 'url',
            render: (url) => <a onClick={() => setVisibleLink(true)}>{url}</a>,
        },
        {
            title: <Text>URL UPDATE</Text>,
            dataIndex: 'updateUrl',
            key: 'updateUrl',
        },
        {
            title: <Text>STATUS</Text>,
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>online</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>offline</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: <Text>TIME STAMP</Text>,
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined className='green-color' />}
                        onClick={() => { setVisible(true); setEditBox(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color' />}
                        onClick={() => { setVisibleModal(true); setDeleteBox({id: row?.id, name: row?.boxName}) }}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Card className='radius-12 border0'>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <ModuleTopHeading
                            name='Add/Edit box'
                            onClick={() => { setVisible(true) }}
                        />
                    </Col>
                    <Col span={12}>
                        <Space align='baseline' className='w-100' size={10} style={{ justifyContent: "end" }}>
                            <IptvSearch
                                name="search"
                                size='large'
                                value={form.getFieldValue('search') || ''}
                                suffix={suffix}
                                placeholder='Search'
                            />

                            <Dropdown
                                menu={{
                                    items,
                                    onClick
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    type='primary'
                                    icon={<FilterOutlined />}
                                    size='large'
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns}
                            dataSource={addboxDetail}
                            style={{ border: '1.81193px solid #ECEEF7', borderRadius: 20, padding: "9px 9px 0 9px" }}
                            scroll={{ x: 1500 }}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loadingAddBoxDetail
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>

            <ImportDevices />

            <AddboxDrawer
                visible={visible}
                editbox={editbox}
                getAllApplications={call}
                onClose={() => { setVisible(false); setEditBox(null) }}
            />
            <ConfirmBoxModal
                visiblemodal={visiblemodal}
                getAllApplications={call}
                onClose={() => { setVisibleModal(false); setDeleteBox(null) }}
                deletebox={deletebox}
            />

            <LinkViewModal
                visiblelink={visiblelink}
                onClose={() => setVisibleLink(false)}
            />
        </div>

    )
}

export { AddEditBox }
