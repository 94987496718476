import React from 'react';
import { ConfigProvider, theme } from 'antd'
import RouteF from './RouteF';
import { BrowserRouter } from 'react-router-dom'
import {store} from "./shared";
import { Provider } from "react-redux"

function App() {
  return (
    <div>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#FE5112',
              borderRadius:3,
              borderRadius3: 3,
              borderrad2: 8,
              borderrad3: 5,
              borderCardrad:20,
              borderCardcolor: '#fff',
              cardshadow:'0px 4px 25px 0px #D9D9D9',
              mainColor: '#FE5112',
              secondarColor:'#001529',
              colorgray: '#3E4854',
              bgtransparent:'transparent',
              border2:'#D9D9D9',
              fonth:'13px'
            }
          }}
        >
          <Provider store={store}>
            <RouteF theme={theme.components} />
          </Provider>
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
