import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, Form, Dropdown, Table, Button } from 'antd'
import { ActionButton, IptvSearch, ModuleTopHeading } from '../../components'
import { VendorShowModal } from '../VendorShowModal';
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
const { Text } = Typography

const suffix = (
    <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
  );

const AppTable = () => {
    const [form] = Form.useForm()
    const [ visible, setVisible] = useState(false)


    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Sort by vendor name</a>,
            key: '2',
        },
    ];


      const columns = [
        {
            title: <Text>MASTER VENDOR NAME</Text>,
            dataIndex: 'mvendorname',
            key: 'mvendorname',
        },
        {
            title: <Text>EMAIL ADDRESS</Text>,
            dataIndex: 'emailaddress',
            key: 'emailaddress',
        },
        {
            title: <Text>TOTAL VENDOR USERS</Text>,
            dataIndex: 'totalvendoruser',
            key: 'totalvendoruser',
        },
        {
            title: <Text>REMAINING TOKEN</Text>,
            dataIndex: 'remainingtoken',
            key: 'remainingtoken',
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            align:'center',
            fixed:'right',
            width:100,
            render: (_,row) => (
                <ActionButton
                    title='View All Vendors'
                    icon={<EyeOutlined className='green-color'/>}
                    onClick={()=>{setVisible(true)}}
                    type='ghost'
                />
            ),
        },
      ];
      const data = [
        {
            key: '1',
            mvendorname: <Text>Nazli Hasan</Text>,
            emailaddress: <Text>abc@gmail.com</Text>,
            totalvendoruser: <Text>100</Text>,
            remainingtoken: <Text>100</Text>
        },
        {
            key: '2',
            mvendorname: <Text >Deniz Ahmed</Text>,
            emailaddress: <Text >abc@gmail.com</Text>,
            totalvendoruser: <Text >100</Text>,
            remainingtoken: <Text >100</Text>
        },
        {
            key: '3',
            mvendorname: <Text >Arda Ibrahim</Text>,
            emailaddress: <Text >abc@gmail.com</Text>,
            totalvendoruser: <Text >100</Text>,
            remainingtoken: <Text >100</Text>
        },
        {
            key: '4',
            mvendorname: <Text >Aylin Murat</Text>,
            emailaddress: <Text >abc@gmail.com</Text>,
            totalvendoruser: <Text >100</Text>,
            remainingtoken: <Text >100</Text>
        },
        {
            key: '5',
            mvendorname: <Text >Can Emre</Text>,
            emailaddress: <Text >abc@gmail.com</Text>,
            totalvendoruser: <Text >100</Text>,
            remainingtoken: <Text >100</Text>
        },
        {
            key: '6',
            mvendorname: <Text >Zeynep Mustafa</Text>,
            emailaddress: <Text >abc@gmail.com</Text>,
            totalvendoruser: <Text >100</Text>,
            remainingtoken: <Text >100</Text>
        },
        {
            key: '7',
            mvendorname: <Text >Asli Mehmat</Text>,
            emailaddress: <Text >abc@gmail.com</Text>,
            totalvendoruser: <Text >100</Text>,
            remainingtoken: <Text >100</Text>
        },
      ];

    
  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col md={12}>
                    <ModuleTopHeading
                        name='Applications'
                    />
                </Col>
                <Col md={12}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <IptvSearch
                            name="search"
                            size='large'
                            value={form.getFieldValue('search') || ''}
                            suffix={suffix}
                            placeholder='Search'
                        />
                        <Dropdown
                            menu={{
                            items
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                        >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                                size='large'
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                        scroll={{x: 1000}}
                        pagination={{ 
                            hideOnSinglePage: true, 
                            total: 12,
                            // total: pagination?.totalRecords,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions:['20', '50', '100'],
                            //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                            showTotal: total => <Button>Total: {total}</Button>
                        }}
                    />
                </Col>
            </Row>
        </Card>
        <VendorShowModal 
            visible={visible} 
            onClose={()=>setVisible(false)} 
        />
    </div>
  )
}

export {AppTable}