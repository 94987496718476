import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Button, Space } from "antd"
import { IptvInput } from "../input"
import { apiCalls } from "../../shared/Apis";
import { checkAuthorization, domainUrl } from "../../shared";

const AddVendorUserDrawer = ({ visible, onClose, editvendor, getVendorApplications }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(visible && editvendor){
            EditVendorApi(editvendor)
        }else{
            form.resetFields()
        }
    },[visible, editvendor])

    const AddUpdateCreateVendor = async (formData) => {
        setLoading(true)
        try {
            const result = await apiCalls.vendorApi.AddUpdateCreateVendor({...formData,id:editvendor})
            setLoading(false);
            if(result){
                onClose();
                getVendorApplications();
            }
        } catch (error){
            console.error("Error updating data",error)
        }
    }

    // edit vendor api
    const EditVendorApi = (id) => {
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/getVendor/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                console.log("Edit vendor", result) 
                form.setFieldsValue(result?.vendor);
                }
            else
            {
                throw  'error'
            }
        })
        .catch(error => {alert(error)})
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            AddUpdateCreateVendor(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };
    
    
    return (
        <div>
            <Drawer
                title={editvendor?'Edit vendor':'Add new master vendor'}
                onClose={onClose}
                open={visible}
                width={700}
                footer={
                    <div className="w-100">
                        <Space className="w-100">
                            <Button
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                block
                                type="primary"
                                loading={loading}
                                onClick={()=>form.submit()}
                            >
                                {
                                    editvendor? 'Update' : 'Save'
                                }
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <IptvInput
                                label='Vendor name'
                                name='name'
                                required
                                message='Please enter name'
                                placeholder='e.g Jhone'
                                value={form.getFieldValue("name") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <IptvInput
                                label='Email address'
                                name='email'
                                required
                                message='Please enter email!'
                                placeholder='e.g abc@example.com'
                                value={form.getFieldValue("email") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <IptvInput
                            label='Password'
                            type='password'
                            name='password'
                            required
                            message={()=>{}}
                            placeholder='e.g A@123456'
                            value={form.getFieldValue("password") || ''}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                    if (!reg.test(value)) {
                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })}
                        />

                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <IptvInput
                                label='Confirm Password'
                                type='password'
                                name='cpassword'
                                dependencies={['password']}
                                required
                                message='Please enter confirm password'
                                placeholder='e.g A@12346'
                                value={form.getFieldValue("cpassword") || ''}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                                validator={({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The password that you entered do not match!'));
                                    },
                                })}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <IptvInput
                                label='Token'
                                required
                                message='Please enter token'
                                name='token'
                                placeholder='e.g 200'
                                value={form.getFieldValue("token") || ''}
                            />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export { AddVendorUserDrawer };
