import React from 'react'
import { AppTable, CardComponent } from '../../components'
import { Space } from 'antd'

const Dashboard = () => {
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <CardComponent />
        <AppTable />
    </Space>
  )
}

export {Dashboard}