import React from 'react'
import { Modal, Table, Typography, theme } from 'antd'

const { Text } = Typography
const { useToken } = theme;
const VendorShowModal = ({visible,onClose}) => {

  const  {token} = useToken()

  const columns = [
    {
        title: <Text style={{color: token.colorgray, fontSize: token.fonth}}>VENDOR NAME</Text>,
        dataIndex: 'venname',
        key: 'venname',
    },
  ];
  const data = [
    {
        key: '1',
        venname: <Text>Nazli Hasan</Text>
    },
    {
        key: '2',
        venname: <Text>Deniz Ahmed</Text>
    },
    {
        key: '3',
        venname: <Text>Arda Ibrahim</Text>
    },
    {
        key: '4',
        venname: <Text>Aylin Murat</Text>
    },
    {
        key: '5',
        venname: <Text>Can Emre</Text>
    },
    {
        key: '6',
        venname: <Text>Zeynep Mustafa</Text>
    },
    {
        key: '7',
        venname: <Text>Asli Mehmat</Text>
    },
    {
        key: '8',
        venname: <Text>Ahmed Hasan</Text>
    },
  ];

  return (
    <Modal
        title="Nazli Hasan - Vendor Users"
        centered
        open={visible}
        onCancel={onClose}
        footer={false}
      >
        <Table
            size='large'
            columns={columns} 
            dataSource={data}     
            pagination={false} 
            style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}    
            scroll={{y: 300}}
        />
      </Modal>

  )
}

export {VendorShowModal}